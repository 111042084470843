<template>
  <div>
    <h2>参数设置</h2>
  </div>
</template>

<script>
export default {
  name: "parameterSsettings",
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
